import { Badge, BadgeProps } from "@chakra-ui/react";
import {
  LoanRequestDerivedStatus,
  LoanRequestStatus,
} from "@obtainly-v2/enums";

export const LoanRequestStatusBadge = ({
  status,
  derivedStatus,
  ...badgeProps
}: BadgeProps & {
  status: LoanRequestStatus;
  derivedStatus?: LoanRequestDerivedStatus;
}) => {
  const overdueActive =
    LoanRequestDerivedStatus.Overdue === derivedStatus &&
    LoanRequestStatus.Active === status;
  const overduePaid =
    LoanRequestDerivedStatus.Overdue === derivedStatus &&
    LoanRequestStatus.Paid === status;
  return (
    <Badge
      colorScheme={
        overdueActive || overduePaid
          ? "critical"
          : [LoanRequestStatus.Approved, LoanRequestStatus.Active].includes(
              status
            )
          ? "success"
          : status === LoanRequestStatus.Pending
          ? "warning"
          : status === LoanRequestStatus.Paid
          ? "info"
          : [LoanRequestStatus.Unpaid, LoanRequestStatus.Rejected].includes(
              status
            )
          ? "critical"
          : "gray"
      }
      {...badgeProps}
    >
      {overdueActive ? derivedStatus : status}
    </Badge>
  );
};
