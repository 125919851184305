import { Box, Button, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import { LoanRequestStatus } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  LoanRequestModel,
  UserModel,
} from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { Table } from "components/common/Table";
import { useTableSort } from "hooks";
import NextLink from "next/link";
import React, { Fragment, useMemo } from "react";
import { useQuery } from "react-query";
import { Column } from "react-table";
import { loanRequestService } from "services";
import {
  formatCurrency,
  formatUnixTime,
  getInterestValue,
  getLoanRequestDerivedStatus,
  getLoanRequestDueDateText,
  getLoanRequestDurationFormat,
  sortFunction,
} from "utils";
import { LoanRequestPaymentSummary } from "./LoanRequestPaymentSummary";
import { LoanRequestStatusBadge } from "./LoanRequestStatusBadge";

type LoanRequestsTableProps = {
  user?: UserModel;
  admin?: AdministratorModel;
  route?: string;
  hiddenColumns?: (
    | "createdAt"
    | "status"
    | "vendor"
    | "client"
    | "creditProvider"
    | "amount"
    | "interest"
    | "duration"
    | "referenceName"
    | "referenceRole"
    | "referenceEmail"
    | "documents"
    | "actions"
    | "due-date"
  )[];
  status?: LoanRequestStatus;
  vendorId?: string | null;
};

export const LoanRequestsTable: React.FC<LoanRequestsTableProps> = ({
  user,
  admin,
  route,
  hiddenColumns,
  status,
  vendorId,
}) => {
  const screenView = useBreakpointValue({
    base: "mobile",
    md: "desktop",
  });
  const { tableQueryProps, updateTableQueryProps, initializeSorting } =
    useTableSort({
      page: 1,
      sort: { id: "createdAt", desc: true },
    });

  const queryArgs: [
    string,
    () => Promise<LoanRequestModel[]>,
    { enabled: boolean }
  ] = !!admin
    ? status
      ? [
          `loan_request_read_many_by_status_${status}_vendor_${vendorId}`,
          () =>
            loanRequestService.readManyBy({
              status: status!,
              vendorId: vendorId!,
            }),
          { enabled: !!vendorId && !!status },
        ]
      : vendorId
      ? [
          `loan_request_read_many_by_status_${status}_vendor_${vendorId}`,
          () =>
            loanRequestService.readManyBy({
              vendorId: vendorId!,
            }),
          { enabled: !!vendorId },
        ]
      : ["loan_request_read_all", loanRequestService.readAll, { enabled: true }]
    : [
        `loan_request_read_many_by_vendor_${user?.businessId}`,
        () =>
          loanRequestService.readManyBy({
            vendorId: user?.businessId!,
          }),
        { enabled: !!user?.businessId },
      ];

  const { data, isLoading } = useQuery(...queryArgs);

  const loanRequests = useMemo(() => {
    if (!data) return [];
    return [...data].sort((a, b) => (b.createdAt > a.createdAt ? -1 : 1));
  }, [data]);

  const columns = React.useMemo(() => {
    if (screenView === "mobile") {
      const mobileColumn: Column<LoanRequestModel> = {
        id: "mobile",
        Header: () => null,
        accessor: "_id",
        Cell: ({ row }) => renderMobileRow(row.original, route),
      };
      return [mobileColumn];
    }

    const defaultColumns: Column<LoanRequestModel>[] = [
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ cell, row }) => (
          <LoanRequestStatusBadge
            status={cell.value}
            derivedStatus={getLoanRequestDerivedStatus(row.original)}
          />
        ),
      },
      {
        id: "createdAt",
        Header: "Submitted On",
        accessor: "createdAt",
        Cell: ({ cell: { value } }) => (
          <>{formatUnixTime(value * 1000) || "-"}</>
        ),
      },
      {
        id: "vendor",
        Header: "Vendor",
        accessor: "vendor",
        sortType: sortFunction("vendor.name"),
        Cell: ({ cell }) => <>{cell.value?.name || "-"}</>,
      },
      {
        id: "client",
        Header: "Client",
        accessor: "client",
        sortType: sortFunction("client.name"),
        Cell: ({ cell }) => <>{cell.value?.name || "-"}</>,
      },
      {
        id: "creditProvider",
        Header: "Credit Provider",
        accessor: "creditProvider",
        sortType: sortFunction("creditProvider.name"),
        Cell: ({ cell }) => <>{cell.value?.name || "-"}</>,
      },
      {
        id: "amount",
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell }) => (
          <Text textAlign="right">{formatCurrency(cell.value)}</Text>
        ),
      },
      {
        id: "duration",
        Header: "Duration",
        accessor: "duration",
        Cell: ({ row: { original: loanRequest } }) => {
          const { intervalCount, unitCount, suffix } =
            getLoanRequestDurationFormat(loanRequest);
          return (
            <Box>
              <Text>{`${intervalCount} ${suffix}`}</Text>
              <Text fontSize="sm" color="gray.700">
                ({unitCount} days)
              </Text>
            </Box>
          );
        },
      },
      {
        id: "interest",
        Header: "Interest",
        accessor: "interest",
        Cell: ({ row: { original: loanRequest } }) => {
          const { amount, interest } = loanRequest;
          const { ratePerInterval, intervalUnit } =
            getLoanRequestDurationFormat(loanRequest);
          return (
            <Box textAlign="end">
              <Text>
                {ratePerInterval}% per {intervalUnit}
              </Text>
              <Text fontSize="sm" color="gray.700">
                {formatCurrency(getInterestValue(amount, interest))}
              </Text>
            </Box>
          );
        },
      },
      {
        id: "due-date",
        Header: "Due on",
        accessor: "_id",
        Cell: ({ row: { original: loanRequest } }) => (
          <Text>{getLoanRequestDueDateText(loanRequest)}</Text>
        ),
      },
      {
        id: "referenceName",
        Header: "Reference Name",
        accessor: "referenceName",
      },
      {
        id: "referenceRole",
        Header: "Reference Role",
        accessor: "referenceRole",
      },
      {
        id: "referenceEmail",
        Header: "Reference Email",
        accessor: "referenceEmail",
      },
      {
        id: "documents",
        Header: "No. of Documents",
        accessor: "documents",
        Cell: ({ cell }) => <>{cell.value?.length || "N/A"}</>,
      },
      {
        id: "actions",
        Header: "Action",
        Cell: ({ row }: any) =>
          !!route ? (
            <NextLink href={`${route}/${row.original?._id}/view`} passHref>
              <Link>
                <Button size="sm">View</Button>
              </Link>
            </NextLink>
          ) : null,
      },
    ];

    if (hiddenColumns?.length) {
      return defaultColumns.filter(
        (column: any) => !hiddenColumns.includes(column.id)
      );
    }
    return defaultColumns;
  }, [hiddenColumns, route, screenView]);

  React.useEffect(() => {
    if (!loanRequests?.length) return;
    initializeSorting();
  }, [loanRequests, initializeSorting]);

  return (
    <Box overflowX="auto">
      <ContentLoader
        isLoading={isLoading}
        contentUnavailable={!loanRequests?.length}
        errorMessage="No funding request found"
      />
      {!!loanRequests?.length && (
        <>
          {/* Show table on desktop devices */}
          <Table
            tableHeaderProps={
              screenView === "mobile" ? { display: "none" } : undefined
            }
            columns={columns}
            data={loanRequests}
            queryProps={{
              ...tableQueryProps,
              onQueryChange: updateTableQueryProps,
            }}
          />
        </>
      )}
    </Box>
  );
};

const renderMobileRow = (item: LoanRequestModel, route?: string) => {
  return (
    <Fragment key={item._id}>
      <Box justifyContent="space-between">
        <LoanRequestStatusBadge
          ml="-4px"
          status={item.status}
          derivedStatus={getLoanRequestDerivedStatus(item)}
        />
        <Text fontWeight="semibold" mt="4px">
          {item.client?.name ?? item.clientId}
        </Text>
      </Box>

      <Box justifyContent="space-between">
        <LoanRequestPaymentSummary loanRequest={item} />
      </Box>

      {!!route ? (
        <NextLink href={`${route}/${item._id}/view`} passHref>
          <Link>
            <Button colorScheme="primary" variant="link" size="sm" mt="15px">
              View
            </Button>
          </Link>
        </NextLink>
      ) : null}
    </Fragment>
  );
};

export default LoanRequestsTable;
