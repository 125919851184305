import { Box, Grid, GridProps, Text, Tooltip } from "@chakra-ui/react";
import { LoanRequestStatus } from "@obtainly-v2/enums";
import { LoanRequestModel } from "@obtainly-v2/schema";
import { useLoanRequestPayment } from "hooks";
import { FC } from "react";
import { RiInformationFill } from "react-icons/ri";
import {
  formatCurrency,
  formatUnixTime,
  getInterestValue,
  getLoanRequestDueDateText,
  getLoanRequestDurationFormat,
} from "utils";
import { NewLoanRequestModel } from "./LoanMultiStepForm";

interface LoanRequestPaymentSummaryProps extends GridProps {
  loanRequest: LoanRequestModel | NewLoanRequestModel;
  isDraft?: boolean;
  isCTA?: boolean;
}

export const LoanRequestPaymentSummary: FC<LoanRequestPaymentSummaryProps> = ({
  loanRequest,
  isDraft = false,
  isCTA = false,
  ...gridProps
}) => {
  const { amount, duration, interest } = loanRequest;
  const payback = amount + getInterestValue(amount, interest);
  const { ratePerInterval, intervalUnit } =
    getLoanRequestDurationFormat(loanRequest);

  const {
    isLoading,
    isActive,
    isPaid,
    amountPayback,
    amountDue,
    amountPaid,
    cashBack,
  } = useLoanRequestPayment(loanRequest as LoanRequestModel);

  return (
    <Grid
      {...gridProps}
      mt="10px"
      gridTemplateColumns={[
        "repeat(auto-fit, minmax(106px, 1fr))",
        "repeat(auto-fit, minmax(160px, 1fr))",
        "1fr 1fr",
      ]}
      gridAutoFlow="row"
      gridGap="10px"
      w="100%"
    >
      <Box w="100%">
        <Text
          fontSize="sm"
          {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
        >
          Amount
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          {formatCurrency(amount, { showCurrency: true })}
        </Text>
      </Box>

      <Box w="100%">
        <Text
          fontSize="sm"
          {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
        >
          Rate
        </Text>
        <Text fontSize="lg">
          <Text as="span" fontWeight="bold">
            {ratePerInterval}%
          </Text>{" "}
          per {intervalUnit}
        </Text>
      </Box>

      <Box w="100%">
        <Text
          fontSize="sm"
          {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
        >
          Pay Back
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          {formatCurrency(amountPayback || payback, {
            showCurrency: true,
          })}
        </Text>
      </Box>

      {(isActive ||
        isPaid ||
        [LoanRequestStatus.Unpaid].includes(loanRequest.status)) && (
        <Box w="100%">
          <Text
            fontSize="sm"
            {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
          >
            Amount Paid
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {isLoading
              ? "..."
              : formatCurrency(amountPaid, {
                  showCurrency: true,
                })}
          </Text>
        </Box>
      )}

      {amountDue > 0 && (
        <Box w="100%">
          <Text
            fontSize="sm"
            {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
          >
            Amount Due
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {isLoading
              ? "..."
              : formatCurrency(amountDue, {
                  showCurrency: true,
                })}
          </Text>
        </Box>
      )}

      {!!loanRequest?.amountOwed && loanRequest?.amountOwed > 0 && (
        <Box w="100%">
          <Text
            fontSize="sm"
            {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
          >
            Amount Owed
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {formatCurrency(loanRequest.amountOwed, {
              showCurrency: true,
            })}
          </Text>
        </Box>
      )}

      {!isCTA && !!cashBack && (
        <Box w="100%">
          <Text
            fontSize="sm"
            {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
          >
            Cash back{" "}
            <Tooltip
              placement="top"
              label={
                isPaid
                  ? `You earned ${formatCurrency(cashBack, {
                      showCurrency: true,
                    })} early payment cash back.`
                  : `Pay today (${formatUnixTime(
                      new Date(),
                      "do MMM, yyyy"
                    )}) and get ${formatCurrency(cashBack, {
                      showCurrency: true,
                    })} cash back.`
              }
            >
              <Text as="span" display="inline-block">
                <RiInformationFill />
              </Text>
            </Tooltip>
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {formatCurrency(cashBack, {
              showCurrency: true,
            })}
          </Text>
        </Box>
      )}

      {isDraft ? (
        <Box w="100%">
          <Text
            fontSize="sm"
            {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
          >
            How long do you need the funding?
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {duration} days
          </Text>
        </Box>
      ) : (
        <>
          <Box w="100%">
            <Text
              fontSize="sm"
              {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
            >
              Due On
            </Text>
            <Text fontSize="lg">
              {getLoanRequestDueDateText(loanRequest as LoanRequestModel)}
            </Text>
            <Text fontSize="sm" color="gray.700">
              ({duration} days)
            </Text>
          </Box>
          {isPaid && (
            <Box w="100%">
              <Text
                fontSize="sm"
                {...(isCTA ? {} : { color: "gray.700", fontWeight: "medium" })}
              >
                Paid On
              </Text>
              <Text fontSize="lg">
                {formatUnixTime(loanRequest.paidBackAt! * 1000)}
              </Text>
            </Box>
          )}
        </>
      )}
    </Grid>
  );
};
